.page-parameters {
  .header-panel {
    padding-left: 20px;
    padding-right: 20px;
    .p-toolbar {
      padding-left: 0px !important;
      padding-right: 0px !important;
      width: 100%;
    }
  }
  .parameters-content {
    height: 100%;
    overflow-y: auto;
    padding: 16px 20px;
  }

  .parameter-view {
    display: flex;
    flex-direction: column;
    height: 100%;

    .header {
      padding: 16px;
      background: $c-surface_surface_white;
      border-bottom: 1px solid $c-surface_surface_20;

      .name {
        display: flex;
        gap: 11px;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: $c-surface_surface_100;
      }
      .p-button-icon-only {
        width: 24px;
        height: 24px;
        padding-left: 15px;
      }
    }
    .content {
      overflow-y: auto;

      .parameter-details {
        margin: 16px;
        .field {
          margin-bottom: 16px;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: $c-surface_surface_100;
          label {
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: $c-surface_surface_60;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .parameter-edit-form {
    .p-button-icon {
      margin: 0;
    }
    .enum-items {
      display: flex;
      gap: 8px;
      margin-bottom: 8px;
      .p-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0;
      }
      .p-button-icon-only {
        svg {
          margin-left: 8px;
        }
      }
    }
    .boolean {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
}
