@mixin wrapper {
  padding-left: 32px !important;
  padding-right: 32px !important;

  @media only screen and (min-width: 1180px) {
    width: 1180px;
    margin: 0 auto;
  }
}
@mixin input-element {
  border: solid 1px $c-surface_surface_30;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 8px;
  font-family: $font-family;
  &:hover {
    border-color: $c-surface_surface_60;
  }
  &:focus {
    border-color: $c-primary_default;
  }
  &.p-inputwrapper-focus {
    border-color: $c-primary_default;
  }
}
@mixin dropdown-panel {
  background-color: $c-surface_surface_white;
  border: solid 1px $c-surface_surface_60;
  border-radius: 4px;
  box-shadow: 0 1px 3px $box-shadow;
  font-size: 14px;
  line-height: 24px;
  margin-top: 2px;
}
