.p-tooltip {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;

  .p-tooltip-text {
    padding: 8px;
    background: $c-surface_surface_100;
    border-radius: 4px;
    color: $c-surface_surface_white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.24);
  }

  &.p-tooltip-right .p-tooltip-arrow {
    border-right-color: $c-surface_surface_100;
  }

  &.p-tooltip-left .p-tooltip-arrow {
    border-left-color: $c-surface_surface_100;
  }

  &.p-tooltip-top .p-tooltip-arrow {
    border-top-color: $c-surface_surface_100;
  }

  &.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: $c-surface_surface_100;
  }
}
