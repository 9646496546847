aside.side-panel {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  transition: all 0.3s ease-in;
  pointer-events: none;
  background-color: rgba($c-surface_surface_100, 0);

  .side-panel-wrapper {
    transition: right 0.3s ease-in;
    background-color: $c-surface_surface_white;
    text-align: left;
    box-shadow: -1px 0px 3px 0px $box-shadow;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    overflow: auto;
    height: 100%;
  }

  &.x-small .side-panel-wrapper {
    width: 58rem;
    right: -58rem;
  }

  &.small .side-panel-wrapper {
    width: 84rem;
    right: -84rem;
  }

  &.medium .side-panel-wrapper {
    width: 500px;
    right: -500px;
  }

  &.wide .side-panel-wrapper {
    width: 720px;
    right: -720px;
  }

  &.side-visible {
    pointer-events: all;
    background-color: rgba($c-surface_surface_100, 0.72);
    & > .side-panel-wrapper {
      right: 0;
    }
  }

  .side-panel-content-container {
    height: 100%;
    display: flex;
    flex-direction: column;

    .side-panel-header {
      display: flex;
      align-items: center;
      padding: 16px 20px;
      background-color: $c-surface_surface_10;
      border-bottom: 1px solid $c-surface_surface_30;
      .title {
        display: flex;
        flex-grow: 1;
        font-weight: 400;
        font-size: 20px;
        line-height: 36px;
      }
      .action {
        .p-button-icon {
          color: $c-surface_surface_100;
        }
        .p-button-icon-only {
          width: 36px;
          height: 36px;
          padding-left: 15px;
        }
      }
    }

    .side-panel-content {
      flex: 1;
      overflow-y: auto;
      color: $c-surface_surface_100;

      h2 {
        line-height: 3.2rem;
      }

      .field {
        label {
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          color: $c-surface_surface_60;
        }

        input,
        textarea {
          width: 64%;
        }
      }
    }

    .side-panel-content-section {
      padding: 20px;
    }

    .side-panel-controls {
      flex: 0 0 68px;
      background-color: $c-surface_surface_10;
      border-top: 1px solid $c-surface_surface_20;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 20px;

      button:not(:last-child) {
        margin-right: 15px;
      }
    }

    .side-panel-controls-split {
      flex: 0 0 68 px;
      background-color: $c-surface_surface_10;
      display: flex;
      align-items: center;
      padding-right: 20px;

      button:not(:last-child) {
        margin-right: 15px;
      }
      .side-panel-controls-split-left {
        display: flex;
        gap: 2px;
        flex-grow: 1;
        margin-left: 2rem;
      }
      .side-panel-controls-split-right {
        gap: 2px;
      }
    }
  }
}
