.heading-with-icon {
  display: flex;
  align-items: center;
  .icon {
    display: flex;
    align-items: center;
    padding-left: 4px;
    padding-right: 8px;
    color: $c-surface_surface_60;
  }
  .label {
    display: block;
    color: $c-surface_surface_100;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
  }
  .debug {
    padding-left: 4px;
    color: $c-surface_surface_40;
  }
}
