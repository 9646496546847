.page-entry-points-statistics {
  .entry-points-statistics-content {
    height: 100%;
    overflow-y: auto;
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    .entry-card {
      max-width: 1000px;

      .details-container {
        display: flex;
        width: 100%;
      }
      .details-container > div {
        margin: 16px;
      }
      .detail {
        flex-grow: 0;
      }
    }
  }
}
