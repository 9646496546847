.entries-selection {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;

  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;

    .column-label {
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: $c-surface_surface_60;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .entry {
      border: 1px solid $c-surface_surface_20;
      border-radius: 4px;

      .entry-filter {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        background-color: $c-surface_surface_10;
      }

      .entry-list {
        overflow-y: scroll;
      }

      .entry-item {
        font-weight: 400;
        font-size: 14px;
        line-height: 33px;

        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;
        overflow: hidden;
        border-bottom: 1px solid $c-surface_surface_20;
        padding: 0 12px;
        cursor: pointer;
        // &:last-child {
        //   border-bottom: none;
        // }
        &:hover {
          background-color: $c-surface_surface_20;
        }
        &:active {
          background-color: $c-surface_surface_30;
        }

        .entry-state {
          width: 24px;
          text-align: center;
        }

        .entry-label {
          width: 100%;
          float: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 40px;
          line-height: '30px';
          padding: 5px;
          box-sizing: 'border-box';
        }

        .entry-action {
          .p-button {
            width: 24px;
            height: 24px;
          }
          .select {
            padding-left: 6px;
          }
          .unselect {
            padding-left: 8px;
          }
          float: left;
          padding-top: 0px;
          margin-top: -7px;
        }
      }
      .not-selectable {
        color: $c-surface_surface_60;
        cursor: default;
        &:hover {
          background-color: transparent;
        }
        &:active {
          background-color: transparent;
        }
      }
    }
  }
}
