.p-divider-vertical {
  margin: 0 0.01rem;
}

.p-divider-vertical:before {
  left: auto;
}

.p-divider-solid.p-divider-vertical:before {
  border-left: 1px solid $c-surface_surface_60;
}
