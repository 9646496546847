.page-home {
  padding-top: 180px;
  text-align: center;
  color: $c-surface_surface_100;

  .header-panel {
    padding-left: 20px;
    padding-right: 20px;
    .p-toolbar {
      padding-left: 0px !important;
      padding-right: 0px !important;
      width: 100%;
    }
  }
}
