.top-bar {
  position: fixed;
  left: 0;
  top: 0;
  height: 72px;
  width: 100%;

  padding: 0;
  color: $c-surface_surface_white;
  z-index: 2;

  .app-name {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
  }

  .background-container {
    position: absolute;
    background-color: $c-primary_default;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;

    .mask-left {
      width: 683px;
      height: 728px;
      left: -303px;
      top: -162px;
    }

    .mask-right {
      width: 500px;
      height: 532px;
      top: -21px;
      right: -144px;
      transform: rotate(30deg);
    }
  }

  .top-bar-content {
    display: none;
    @media only screen and (min-width: 1200px) {
      width: 123rem;
    }
    margin: 0 auto;
    .p-button {
      color: $c-primary_default;
    }
  }

  & + main {
    margin-top: 72px;
    height: calc(100% - 72px);
  }

  &.top-bar-medium {
    height: 248px;

    & + main {
      margin-top: 248px;
      height: calc(100% - 248px);
    }

    .background-container {
    }

    .top-bar-content {
      display: block;
      margin-top: 3.6rem;
    }
  }

  &.top-bar-large {
    height: 34.5rem;

    .background-container {
      .mask-right {
        top: 95px;
        right: -144px;
      }
    }

    .top-bar-content {
      display: block;
      margin-top: 3.6rem;
    }

    & + main {
      margin-top: 34.5rem;
      height: calc(100% - 34.5rem);
    }
  }

  &.top-bar-full {
    height: calc(100%);

    .background-container {
      .mask-right {
        top: 95px;
        right: -144px;
      }
    }

    .top-bar-content {
      display: block;
      margin-top: 3.6rem;
    }

    & + main {
      margin-top: 0;
      height: 0;
    }
  }

  .top-bar-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-top: 2.4rem;

    .p-menuitem {
      font-weight: 700;
      font-size: 14px;
      line-height: 32px;
      height: 72px;
      margin: 0 auto;
      display: flex;
      padding: 0;

      a.p-menuitem-link {
        padding: 0 16px;
      }

      &:hover {
        background-color: $c-primary_hover;
      }
      &.active {
        background-color: $c-primary_select;
      }
      .p-menuitem-text {
        color: $c-surface_surface_white;
      }
      .p-menuitem-icon {
        text-align: center;
        color: $c-surface_surface_white;
        margin-right: 12px;
      }
      .p-menuitem-link {
        opacity: 0.84;
      }

      &.p-highlight {
        background-color: $c-surface_surface_white;

        .p-menuitem-link {
          opacity: 1;
          color: $c-primary_default;
        }
      }
    }
  }

  .account-menu {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    .p-button {
      padding: 0px 0px 0px 8px;
    }
    .p-menu {
      .p-menuitem {
        height: 40px;
        background-color: $c-surface_surface_white;
        .p-menuitem-text {
          color: $c-surface_surface_100;
        }
        &:hover {
          background-color: $c-surface_surface_20;
        }
      }
    }
  }
  .account-menu-active,
  .account-menu:hover {
    cursor: pointer;
    background-color: rgba($c-surface_surface_white, 0.1);
    border-radius: 4px;
  }
  .user {
    text-align: right;

    .greeting {
      font-size: 14px;
      line-height: 20px;

      .user-name {
        font-weight: 700;
      }
    }
    .user-role {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $c-surface_surface_white;
    }
  }

  .p-avatar {
    margin-right: 4px;
  }

  .p-button {
    color: $c-surface_surface_white;
  }

  .fa,
  .fa-regular {
    font-size: 16px;
    line-height: 24px;

    &.fa-chevron-down {
      transition: all 0.2s;
    }
  }

  .reversed {
    transform: rotate(180deg);
  }

  .fa-chevron-down {
    width: 24px;
    height: 24px;
  }

  .p-tabmenu {
    position: absolute;
    bottom: 0;
  }
}

.top-bar-left,
.top-bar-right {
  display: flex;
  align-items: center;
  z-index: 5;
}

.logout-button {
  background: transparent;
  border: none;
  color: red;
  font-weight: 700;
  font-size: 18px;
}

.empty-icon {
  margin-right: 24px !important;
}
