.page-entry-point-statistics-editor {
  &.entry-point-statistics-preview {
    .content-editor-section {
      .body {
        padding: 0;

        .split-panel {
          display: flex;

          .editor-preview {
            flex: 1 1 0;

            .preview-title {
              font-weight: 600;
              padding: 16px;
              background-color: $c-surface_surface_20;
              color: $c-surface_surface_60;
            }

            .preview-content {
              padding: 16px;
            }

            .sms-preview {
              top: 185px;
              left: 98px;
            }

            .notification-preview {
              top: 185px;
              left: 106px;
            }
          }
        }
      }
    }
  }
}
