.page-workflows {
  .workflows-content {
    height: 100%;
    overflow-y: auto;
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    .entry-card {
      max-width: 1000px;
    }
  }

  .workflow-edit-form {
    .boolean {
      display: flex;
      gap: 8px;
      align-items: center;
    }
    .boolean + .boolean {
      margin-top: 8px;
    }
  }
}
