.content-editor {
  .top-bar {
    color: $c-surface-surface-100;
    background-color: $c-surface-surface-white;
    border-top: 2px solid $c-primary_default;
    border-bottom: 1px solid $c-surface-surface-20;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    .action-button {
      color: $c-surface-surface-100;
      background: $c-surface-surface-white;
      border: 1px solid $c-surface-surface-30;
      border-radius: 3px;
      padding: 8px;
      &:active {
        background-color: $c-surface_surface_30;
      }
      &:hover {
        background-color: $c-surface_surface_20;
        border-color: $c-surface-surface-60;
      }
    }
    .p-button-icon {
      font-size: 16px;
      line-height: 24px;
      margin-right: 8px;
    }

    .logo-with-action {
      display: flex;
      align-items: center;
      color: $c-surface-surface-white;
      background-color: $c-primary_default;
      height: 100%;
      svg {
        height: 69px;
      }
      .p-button {
        margin: 8px 12px 8px 0px;
        border: 1px solid transparent;
        &:hover {
          border: 1px solid $c-surface-surface-white;
          background-color: transparent;
          border-radius: 3px;
        }
      }
    }
    .separator {
      border-left: 1px solid $c-surface-surface-20;
      height: 24px;
    }

    .header {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      .entry-point {
        .type {
          display: flex;
          align-items: center;
          gap: 4px;
          color: $c-surface-surface-60;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
        }
        .name {
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
        }
      }
      .actions {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        .p-tabmenu {
          border-bottom: none;
          overflow: hidden;
          svg {
            color: $c-surface-surface-100;
            width: 20px;
            height: 20px;
          }
          .p-highlight {
            svg {
              color: $c-primary_default;
            }
          }
        }
      }
      .actions-right {
        margin-right: 16px;
      }
    }
  }
  .main-sidebar {
    .objects-dropdown {
      svg {
        color: $c-surface-surface-60;
        top: 2px;
        position: relative;
      }
    }
    .objects-filter {
      margin-top: 8px;
    }
    .field {
      label {
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: $c-surface-surface-60;
      }
    }
  }
  .compile {
    .p-button {
      background: $c-surface-surface-white;
      color: $c-surface-surface-100;
      border: 1px solid $c-surface_surface_30;
      border-radius: 3px;
      &:hover,
      &:focus {
        border-color: $c-surface-surface-60;
        background-color: $c-surface_surface_20;
      }
    }
  }
  .save-panel {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    flex-direction: row-reverse;
    .saved {
      margin-right: 12px;
      color: $c-status_green_80;
    }
    .unsaved,
    .saving {
      margin-right: 12px;
      color: $c-status_yellow_80;
    }
  }
  .editor {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.matrix-preview {
      .content-with-header {
        width: 100%;
      }

      .p-dropdown {
        padding: 2px 4px;
      }

      .p-dropdown-label {
        font-size: 12px;
      }

      .p-column-filter-element {
        background-color: white;
      }

      .p-datatable .p-datatable-thead th {
        padding: 4px 8px;

        &.p-filter-column {
          padding: 4px 0px;
        }
      }
    }

    .content-with-header {
      width: 1000px;
      .header {
        .content {
          .languages-header {
            display: flex;
            gap: 12px;
            align-items: center;
            .options {
              margin-top: 0;
              .p-button {
                padding: 0;
                svg {
                  color: $c-surface_surface_60;
                  margin: 0;
                }
              }
              .p-disabled {
                opacity: 1;
              }
              .default-language {
                display: flex;
                gap: 7px;
                align-items: center;
                padding: 4px 7px 4px 8px;
              }
              .settings {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                margin-left: -1px;
              }
            }
          }
        }
      }
      .body {
        .no-item-selected {
          padding-top: 80px;
        }
        &:nth-child(3):has(.monaco-editor-wrapper) {
          padding: 0;
        }

        .compact {
          .field {
            display: inline-block;
            margin-bottom: 8px;
            margin-right: 16px;
          }
          .options {
            display: inline-flex;
            margin-left: 8px;
            .p-button {
              padding: 4px 8px;
            }
          }
        }
      }
    }
    .field {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      .title {
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: $c-surface-surface-60;
      }
    }
    .options {
      margin-top: 8px;
      display: flex;
      align-items: center;
      .p-button {
        color: $c-surface-surface-100;
        background-color: $c-surface-surface-white;
        border: 1px solid $c-surface-surface-30;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        border-radius: 0px;

        text-align: center;
        white-space: nowrap;
        max-width: 100%;
        box-sizing: border-box;
        margin: 0;

        &:active {
          background-color: $c-surface_surface_30;
        }
        &:hover {
          color: $c-surface-surface-100;
          background-color: $c-surface_surface_20;
          border-color: $c-surface-surface-30;
        }
        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        &.active {
          color: $c-surface-surface-white;
          background-color: $c-primary_default;
          border-color: $c-primary_select;
          &:active {
            background-color: $c-primary_select;
          }
          &:hover {
            background-color: $c-primary_hover;
          }
        }

        .semaphore {
          margin-left: 10px;
        }
      }
    }
    .langs-using-default {
      color: $c-surface-surface-100;
      border: 1px solid $c-status_blue_80;
      background-color: $c-status_blue_20;
      border-radius: 4px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 45px;
        background-color: $c-status_blue_80;
        color: $c-surface-surface-white;
        margin-left: -1px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      .content {
        padding: 12px;
      }
    }
    .languages-section {
      display: flex;
      flex-flow: wrap;
      align-items: center;
      gap: 0;
      .p-button {
        min-width: 64px;
        min-height: 28px;
        border-radius: 0px;
        padding: 0;
        &:hover {
          border-color: $c-surface-surface-60;
          background-color: $c-surface_surface_20;
        }
        .fi {
          margin-left: 12px;
        }
        &:first-child {
          border-radius: 0px;
        }
        &:last-child {
          border-radius: 0px;
        }
        .language-semaphore {
          margin: 0 8px;
        }
      }
      .p-disabled {
        opacity: 1;
      }
    }
  }
  .editor-preview {
    text-align: center;
    position: relative;

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    .preview-content {
      position: relative;
    }

    .notification-preview {
      position: absolute;
      color: $c-surface-surface-100;
      background: rgb(229, 231, 234);
      width: 287px;
      border-radius: 4px;
      padding: 0 10px 10px 10px;
      top: 118px;
      left: 340px;
      text-align: left;

      .subject {
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .sms-preview {
      position: absolute;
      color: $c-surface-surface-100;
      top: 118px;
      left: 50%;
      transform: translateX(-50%);
      text-align: left;

      p {
        display: -webkit-box;
        max-width: 200px;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .sms-bubble {
        --r: 1em;
        --t: 1.5em;
        --_p: 0;
        min-width: 276px;
        max-width: 276px;
        max-height: 560px;
        padding: 1em;
        border-inline: var(--t) solid #0000;
        border-radius: calc(var(--r) + var(--t)) / var(--r);
        mask: radial-gradient(100% 100% at var(--_p) 0, #0000 99%, #000 102%) var(--_p) 100% / var(--t) var(--t)
            no-repeat,
          linear-gradient(135deg, #fe6d00, #1384c5) padding-box;
        background: rgb(229, 231, 234) border-box;
        border-bottom-left-radius: 0 0;
        place-self: start;
        overflow-y: auto;
      }
    }

    .sms-footer-info {
      color: rgb(172, 172, 172);
      text-align: right;
      padding-top: 4px;
      padding-right: 16px;
    }

    .email-preview {
      text-align: left;

      .title {
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: $c-surface-surface-60;
      }

      .text-border {
        border: 1px solid #ccc;
        padding: 8px;

        .email-body {
          min-height: 326px;
        }
      }
    }
  }
}

.content-editor-section {
  .body {
    padding: 0;
    overflow-x: auto;

    .split-panel {
      display: flex;

      .editor-preview {
        flex: 1 1 0;

        .preview-title {
          font-weight: 600;
          padding: 16px;
          background-color: $c-surface_surface_20;
          color: $c-surface_surface_60;
        }

        .preview-content {
          margin: 16px;
        }

        .notification-preview {
          top: 185px;
          left: 106px;
        }
      }
    }
  }
}
