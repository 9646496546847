$input-switch-size: 16px;

.p-inputswitch {
  width: 36px;
  height: 20px;

  .p-inputswitch-slider {
    background: $c-surface_surface_30;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 12px;

    &::before {
      background: $c-surface_surface_10;
      width: $input-switch-size;
      height: $input-switch-size;
      left: 2px;
      margin-top: -8px;
      border-radius: 50%;
      transition-duration: 0.2s;
    }
  }

  &.p-inputswitch-checked {
    .p-inputswitch-slider {
      background: $c-primary_default;

      &::before {
        background: $c-surface_surface_white;
        transform: translateX($input-switch-size);
      }
    }
  }
}
