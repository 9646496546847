.sidebar-entry-view {
  .header {
    padding: 16px;
    background: $c-surface_surface_white;
    border-bottom: 1px solid $c-surface_surface_20;
    display: flex;
    gap: 11px;

    .name {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: $c-surface_surface_100;
    }
    .icon-only {
      color: $c-surface_surface_40;
    }
    .p-button-icon-only {
      width: 24px;
      height: 24px;
      padding-left: 15px;
    }
  }
  .content {
    overflow-y: auto;

    .entry-detail {
      margin: 16px;
      .field {
        margin-bottom: 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $c-surface_surface_100;
        label {
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          color: $c-surface_surface_60;
          margin-bottom: 0;
        }
      }
    }
  }
}
