.no-item-selected {
  padding-top: 180px;
  text-align: center;
  color: $c-surface_surface_30;

  svg {
    margin-bottom: 16px;
  }

  .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: $c-surface_surface_100;
  }
  .note {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $c-surface_surface_60;
  }
}
