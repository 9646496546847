.p-treetable {
  .p-treetable-scrollable-header {
    border-bottom: 1px solid $c-surface-surface-20;
  }
  .p-treetable-scrollable-body {
    overflow-y: auto !important;
  }
  .p-treetable-header {
    margin: 16px 16px 20px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    .heading {
      margin-bottom: 12px;
    }
  }
  .p-treetable-tbody {
    .changed-row {
      background-color: #e0f7fa !important;
    }
    tr {
      border-bottom: 1px solid $c-surface-surface-20;
      &:hover {
        background-color: $c-surface_surface_20;
      }
      &:active {
        background-color: $c-surface_surface_30;
      }
    }
    td {
      display: flex;
      gap: 4px;
      padding-left: 16px;
    }
  }
  .parameter {
    display: flex;
    flex-grow: 1;
    &.item {
      cursor: pointer;
    }
    &.parent {
      cursor: default;
    }

    .name {
      flex-grow: 1;
      font-size: 14px;
      line-height: 33px;
      &.item {
        font-weight: 400;
      }
      &.parent {
        font-weight: 500;
      }
      .used-variable {
        --fa-secondary-opacity: 1;
        --fa-primary-color: #ffffff; // $c-surface_surface_white
        --fa-secondary-color: #5daf82; // $c-status_green_60
        padding-left: 4px;
      }
    }

    .action {
      float: right;
      margin: 4px 12px;

      .p-button {
        width: 24px;
        height: 24px;
        svg {
          margin-left: -5px;
          margin-top: 1px;
        }
      }
    }
  }
}
