.content-semaphore {
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  padding: 0 6px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  font-weight: bold;
  color: $c-surface_surface_10;

  svg {
    color: $c-surface_surface_40;
  }

  &.success {
    background: $c-status_green_60;
    border-color: $c-status_green_60;
  }

  &.warning {
    background: $c-status_yellow_60;
    border-color: $c-status_yellow_60;
  }

  &.error {
    background: $c-status_red_60;
    border-color: $c-status_red_60;
  }
}
