.p-datatable {
  text-align: left;
  border-radius: 4px;
  overflow: hidden;

  .p-datatable-table {
    .selected {
      background-color: $c-surface_surface_20;
    }
  }
  .p-datatable-thead {
    th {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: $c-surface_surface_60;
      padding: 12px;
    }
  }
  .p-datatable-tbody {
    background-color: $c-surface_surface_white;
    border-radius: 4px;

    tr {
      border: solid 1px $c-surface_surface_30;
    }
    tr:hover {
      background-color: $c-surface_surface_20;
      cursor: pointer;
    }
    td {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      padding: 12px;
    }
  }

  .max-records-shown-message {
    font-size: 14px;
    text-align: center;
    padding: 0.8rem 1.6rem;
    color: $c-surface_surface_40;
  }
}

.matrix-preview-cell {
  max-height: 200px;
  overflow: auto;
  text-overflow: ellipsis;

  .content-label {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: $c-surface_surface_60;
  }
}

.matrix-preview {
  .p-datatable .p-datatable-tbody td {
    font-size: 12px;
    padding: 6px;
  }

  .preview-icon-button {
    color: $c-surface_surface_60;
    transition: color 0.3s ease;

    &:hover {
      color: $c-primary_hover;
    }
  }
}

.reset-table {
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;

    th,
    td {
      padding: 1px !important;
      text-align: left;
      vertical-align: top;
      border: none;
      background: none;
    }

    th {
      font-weight: bold;
    }

    tr {
      border: none !important;
      background-color: transparent !important;
    }
  }
}
