.p-button {
  background-color: $c-primary_default;
  color: $c-surface_surface_white;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  font-family: $font-family;
  border: none;
  border-radius: 4px;

  svg {
    margin-right: 6px;
  }

  &:enabled {
    &:hover,
    &:focus {
      background-color: $c-primary_hover;
    }
  }

  &:disabled {
    opacity: 0.6;
  }

  .p-button-icon {
    font-size: 16px;
    line-height: 24px;
    margin-right: 8px;
  }

  .p-button-icon-align-right {
    margin-left: 8px;
    margin-right: 0;
  }

  .p-button-label {
    line-height: 24px;
  }

  &.p-button-secondary {
    background-color: transparent;
    color: $c-surface_surface_100;
    border: none;

    &:hover {
      border-radius: 4px;
      border-color: $c-surface-surface-60;
      background-color: $c-surface_surface_20;
    }
    &:active {
      border-radius: 4px;
      color: $c-primary_default;
      background-color: $c-surface_surface_30;
    }
    &:focus {
      background: transparent;
    }
  }
  &.p-button-ellipsis {
    background-color: transparent;
    color: $c-surface_surface_100;
    border: none;
    width: 28px;
    height: 28px;
    padding: 0px;
    margin: 0px;
    svg {
      margin-left: 8px;
    }

    &:hover {
      border-radius: 4px;
      color: $c-surface_surface_100;
      background-color: $c-surface_surface_30;
    }
    &:active {
      border-radius: 4px;
      color: $c-surface_surface_100;
      background-color: $c-surface_surface_30;
    }
    &:focus {
      background: transparent;
    }
  }

  &.p-confirm-dialog-reject,
  &.p-button-cancel {
    background-color: transparent;
    color: $c-surface_surface_100;
    border: none;
    &:hover {
      border-radius: 4px;
      background-color: transparent;
    }
  }

  &.p-button-text,
  &.p-button-link {
    background: transparent;

    &:hover,
    &:focus {
      background: transparent;
    }
  }
}

.p-dialog-content {
  gap: 16px;
}

.p-confirm-dialog-icon {
  line-height: 1;
}

.p-dialog-footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.p-button-icon-only {
  svg {
    margin-right: 8px;
  }
}

.p-button-mini {
  padding: 0.3rem 0.8rem;
}

.p-button-danger {
  color: $c-status_red_60;
}

.p-confirm-dialog-accept,
.p-button-primary-danger {
  background-color: $c-status_red_60;
  color: $c-surface_surface_white;
  &:enabled {
    &:hover,
    &:focus {
      background-color: $c-status_red_60;
    }
  }
}

.a-button {
  text-decoration: none;
}

.p-button-primary {
  background-color: $c-primary_default !important;
}

.p-button-secondary {
  background: $c-surface_surface_10 !important;
  color: $c-surface_surface_60;
  border: 1px solid $c-surface_surface_30 !important;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  padding: 4px 8px;
  svg {
    color: $c-surface_surface_40;
  }
}
