.page-master-template-editor {
  .content-with-header {
    margin: 16px;
    .body {
      padding: 0;
      .no-item-selected {
        padding: 80px;
      }
    }
  }

  .html-body-editor {
    min-height: 442px;
  }

  &.html-preview {
    .content-with-header {
      height: calc(100% - 117px);

      .body {
        height: calc(100% - 54px);

        .split-panel {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          height: 100%;
          .editor-preview {
            height: 100%;
            text-align: left;
            flex: 1 1 0;
            width: 0;

            &:nth-child(2) {
              border-left: 1px solid $c-surface_surface_60;
            }

            &:has(.no-item-selected) {
              min-height: 300px;
            }

            .preview-title {
              font-weight: 600;
              padding: 16px;
              background-color: $c-surface_surface_20;
              color: $c-surface_surface_60;
            }

            .preview-content {
              padding: 16px;
              line-height: 0;
              overflow-y: auto;
              height: 100%;

              &.with-title {
                height: calc(100% - 48px);
              }
            }
          }
        }
      }
    }
  }
}
