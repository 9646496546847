.page-snippet-editor {
  .content-with-header {
    margin: 16px;
    .body {
      padding: 0;
      .no-item-selected {
        padding: 80px;
      }
    }
  }
  &.snippet-preview {
    .content-with-header {
      height: calc(100% - 117px);
      .body {
        height: 100%;

        .split-panel {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          height: 100%;
          .editor-preview {
            height: calc(100% - 101px);
            text-align: left;
            flex: 1 1 0;
            width: 0;

            &:nth-child(2) {
              border-left: 1px solid $c-surface_surface_60;
            }

            &:has(.no-item-selected) {
              min-height: 300px;
            }

            .preview-title {
              font-weight: 600;
              padding: 16px;
              background-color: $c-surface_surface_20;
              color: $c-surface_surface_60;
            }

            .preview-content {
              padding: 16px;
              overflow-y: auto;
              height: calc(100% - 48px);
            }
          }
        }
      }
    }
  }
}
