.main-layout-with-sidebar {
  display: flex;
  flex-direction: row;
  height: 100%;

  .main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    min-width: 1032px; // 1000 + paddings

    .disabled {
      color: $c-surface_surface_20;
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .main-sidebar {
    background-color: $c-surface_surface_white;
    &.left {
      flex: 0 0 400px;
      border-right: 1px solid $c-surface_surface_20;
    }
    &.right {
      flex: 0 0 500px;
      border-left: 1px solid $c-surface_surface_20;
    }
  }
}
