.monaco-editor-wrapper {
  border: 1px solid transparent;

  .monaco-editor {
    .margin {
      background-color: $c-surface_surface_10;
      .line-numbers {
        font-family: 'Roboto Mono';
        color: $c-surface_surface_60;
      }
      .view-line {
        font-family: 'Roboto Mono';
      }
    }
  }
}
