.page-configuration {
  height: 100%;
  overflow-y: auto;

  .p-toolbar {
    width: 860px;
  }

  .configuration-content {
    height: 100%;
    overflow-y: auto;
    padding: 16px 20px;

    .entry-card {
      max-width: 800px;
      padding: 16px;
      margin-bottom: 16px;
      .detail {
        margin: 0;
        .name {
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
        }
        .actions {
          display: flex;
          gap: 8px;
          align-items: center;
          margin-top: 16px;
          .default-language {
            width: 344px;
            height: 36px;
          }
          .p-button {
            color: $c-surface-surface-100;
            background: $c-surface_surface_white;
            border: 1px solid $c-surface_surface_30;
            border-radius: 3px;
            height: 36px;
            &:hover {
              background-color: $c-surface_surface_white;
              border-color: $c-surface-surface-60;
            }
          }
        }
      }
    }
  }
}
