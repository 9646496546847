body {
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $c-surface_surface_10;
}

* {
  box-sizing: border-box;
}

.page {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.app {
  text-align: center;
}

.app-loading {
  margin-top: 25px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
}

.app-error {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 10px;
  margin-top: 12px;
  margin-bottom: 12px;
}

main {
  width: 100%;
  overflow: auto;

  .content-wrapper {
    @media only screen and (min-width: 600px) {
      padding: 16px 32px;
    }
    max-width: 118px;
    margin: 0 auto;
  }
}

.d-flex {
  display: flex;
  align-items: center;
}

.gap-2 {
  gap: 2px;
}

.icon-only {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background: $c-surface_surface_10;
  border: 1px solid $c-surface_surface_30;
  border-radius: 4px;
}

.p-error {
  color: $c-status_red_80;
}

.wrapper {
  @include wrapper;
}

.border-bottom-gray {
  border-bottom: 1px solid $c-surface-surface-20;
}
