input.p-inputtext {
  appearance: none;
  @include input-element;
  &::placeholder {
    color: rgba($c-surface_surface_60, 0.4);
  }

  &:focus {
    outline: none;
  }
}

input.p-inputtext.p-component.p-disabled {
  background-color: $c-surface_surface_white;
  opacity: 0.75;
}

textarea.p-inputtext {
  @include input-element;
  &:focus {
    outline: none;
  }
}

input.p-inputtext.p-invalid {
  border-color: $c-status_red_80;
  background-color: $c-status_red_20;
  color: $c-surface_surface_100;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.p-input-icon-left {
  .p-inputtext {
    padding-left: 28px;
    padding-top: 10px;
  }
  svg {
    left: 7px;
    color: $c-surface_surface_50;
  }
}
.p-inputgroup {
  border: solid 1px $c-surface_surface_30;
  border-radius: 4px;

  input.p-inputtext {
    border: none;
  }

  .p-inputgroup-addon {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    padding: 8px;
    background-color: $c-surface_surface_white;
    border-radius: 4px;

    .icon {
      color: $c-surface_surface_60;
    }
    .icon-active {
      color: $c-surface_surface_100;
    }
    & + .p-inputtext {
      padding-left: 0;
    }
  }
}
