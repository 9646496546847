.p-dialog-mask {
  background-color: $c-surface_surface_overlay !important;
}

.p-dialog {
  background-color: $c-surface_surface_white;
  border-radius: 4px;
  padding: 2rem;
  box-shadow: 0 1px 3px $box-shadow;
  font-size: 14px;

  .p-dialog-header-icons {
    align-self: auto;
  }
}

.p-dialog-header {
  margin-bottom: 20px;
}

.p-dialog-footer {
  margin-top: 30px;
  text-align: right;

  .p-button {
    font-weight: 500;
  }
}

.p-dialog-title {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
}
