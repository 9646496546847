.jodit-wysiwyg {
  font-size: 12px;
  table tr td {
    padding: initial;
  }
}

.jodit-content {
  display: inline-flex;
  user-select: none;
  align-items: center;
  height: 24px;
  background-color: $c-status_green_20;
  border: 1px solid $c-status_green_60;
  border-radius: 4px;
  padding: 0 5px;
  cursor: default;
}

.jodit-variable {
  display: inline-flex;
  user-select: none;
  align-items: center;

  height: 24px;
  background-color: $c-status_green_20;
  border: 1px solid $c-status_green_60;
  border-right: 0;
  border-radius: 4px;
  padding: 0;
  cursor: default;

  &.codeblock::before {
    content: url("data:image/svg+xml,%3Csvg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.3867 1.4375L7.73047 13.2188C7.62891 13.5234 7.29883 13.7012 6.96875 13.5996C6.66406 13.498 6.48633 13.168 6.58789 12.8379L10.2441 1.05664C10.3457 0.751953 10.6758 0.574219 11.0059 0.675781C11.3105 0.777344 11.4883 1.10742 11.3867 1.4375ZM13.6719 3.64648L16.9219 6.69336C17.0488 6.79492 17.125 6.97266 17.125 7.125C17.125 7.30273 17.0488 7.45508 16.9219 7.58203L13.6719 10.6289C13.418 10.8574 13.0371 10.8574 12.8086 10.6035C12.5801 10.3496 12.5801 9.96875 12.834 9.74023L15.6016 7.125L12.834 4.53516C12.5801 4.30664 12.5801 3.92578 12.8086 3.67188C13.0371 3.41797 13.418 3.41797 13.6719 3.64648ZM5.14062 4.53516L2.37305 7.125L5.14062 9.74023C5.39453 9.96875 5.39453 10.3496 5.16602 10.6035C4.9375 10.8574 4.55664 10.8574 4.30273 10.6289L1.05273 7.58203C0.925781 7.45508 0.875 7.30273 0.875 7.125C0.875 6.97266 0.925781 6.79492 1.05273 6.69336L4.30273 3.64648C4.55664 3.41797 4.9375 3.41797 5.16602 3.67188C5.39453 3.92578 5.39453 4.30664 5.14062 4.53516Z' fill='%23707070'%3E%3C/path%3E%3C/svg%3E");
    position: relative;
    top: 2px;
    margin-left: 2px;
    margin-right: 2px;
  }
  &.param::before {
    content: url("data:image/svg+xml,%3Csvg width='12' height='14' viewBox='0 0 12 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.25 6.5H11.25C11.6562 6.5 12 6.84375 12 7.25C12 7.6875 11.6562 8 11.25 8H7.75C7.3125 8 7 7.6875 7 7.25C7 6.84375 7.3125 6.5 7.75 6.5H8.75V2.8125L8.21875 2.96875C7.84375 3.09375 7.40625 2.90625 7.28125 2.5C7.15625 2.125 7.34375 1.6875 7.75 1.5625L9.25 1.0625C9.46875 0.96875 9.71875 1.03125 9.9375 1.15625C10.125 1.3125 10.25 1.53125 10.25 1.75V6.5ZM3.25 15.5H4.25C4.65625 15.5 5 15.8438 5 16.25C5 16.6875 4.65625 17 4.25 17H0.75C0.3125 17 0 16.6875 0 16.25C0 15.8438 0.3125 15.5 0.75 15.5H1.75V11.8125L1.21875 11.9688C0.84375 12.0938 0.40625 11.9062 0.28125 11.5C0.15625 11.0938 0.34375 10.6875 0.75 10.5625L2.25 10.0625C2.46875 9.96875 2.71875 10 2.9375 10.1562C3.125 10.2812 3.25 10.5312 3.25 10.75V15.5ZM0 3.25C0 2.03125 1 1 2.25 1H3.75C4.96875 1 6 2.03125 6 3.25V5.75C6 7 4.96875 8 3.75 8H2.25C1 8 0 7 0 5.75V3.25ZM2.25 2.5C1.8125 2.5 1.5 2.84375 1.5 3.25V5.75C1.5 6.1875 1.8125 6.5 2.25 6.5H3.75C4.15625 6.5 4.5 6.1875 4.5 5.75V3.25C4.5 2.84375 4.15625 2.5 3.75 2.5H2.25ZM9.75 10C10.9688 10 12 11.0312 12 12.25V14.75C12 16 10.9688 17 9.75 17H8.25C7 17 6 16 6 14.75V12.25C6 11.0312 7 10 8.25 10H9.75ZM7.5 14.75C7.5 15.1875 7.8125 15.5 8.25 15.5H9.75C10.1562 15.5 10.5 15.1875 10.5 14.75V12.25C10.5 11.8438 10.1562 11.5 9.75 11.5H8.25C7.8125 11.5 7.5 11.8438 7.5 12.25V14.75Z' fill='%23707070'%3E%3C/path%3E%3C/svg%3E");
    position: relative;
    top: 2px;
    margin-left: 4px;
    margin-right: 4px;
  }
  &.subtemplate::before {
    content: url("data:image/svg+xml,%3csvg width='12' height='14' viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M11.4062 2.9375L9.0625 0.59375C8.6875 0.21875 8.1875 0 7.65625 0H2C0.875 0 0 0.90625 0 2V14C0 15.125 0.875 16 2 16H10C11.0938 16 12 15.125 12 14V4.34375C12 3.8125 11.7812 3.3125 11.4062 2.9375ZM10.5 14C10.5 14.2812 10.25 14.5 10 14.5H2C1.71875 14.5 1.5 14.2812 1.5 14V2.03125C1.5 1.75 1.71875 1.53125 2 1.53125H7V4C7 4.5625 7.4375 5 8 5H10.4688V14H10.5ZM3 8.75C3 9.1875 3.3125 9.5 3.75 9.5H8.25C8.65625 9.5 9 9.1875 9 8.75C9 8.34375 8.65625 8 8.25 8H3.75C3.3125 8 3 8.34375 3 8.75ZM8.25 11H3.75C3.3125 11 3 11.3438 3 11.75C3 12.1875 3.3125 12.5 3.75 12.5H8.25C8.65625 12.5 9 12.1875 9 11.75C9 11.3438 8.65625 11 8.25 11Z' fill='%23707070'%3e%3c/path%3e%3c/svg%3e");
    position: relative;
    top: 2px;
    margin-left: 4px;
    margin-right: 4px;
  }
  &.image::before {
    content: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 512 512' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M64 64C46.3 64 32 78.3 32 96V329.4l67.7-67.7c15.6-15.6 40.9-15.6 56.6 0L224 329.4 355.7 197.7c15.6-15.6 40.9-15.6 56.6 0L480 265.4V96c0-17.7-14.3-32-32-32H64zM32 374.6V416c0 17.7 14.3 32 32 32h41.4l96-96-67.7-67.7c-3.1-3.1-8.2-3.1-11.3 0L32 374.6zM389.7 220.3c-3.1-3.1-8.2-3.1-11.3 0L150.6 448H448c17.7 0 32-14.3 32-32V310.6l-90.3-90.3zM0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm160 48a16 16 0 1 0 -32 0 16 16 0 1 0 32 0zm-64 0a48 48 0 1 1 96 0 48 48 0 1 1 -96 0z' fill='%23707070'%3e%3c/path%3e%3c/svg%3e");
    position: relative;
    top: 2px;
    margin-left: 4px;
    margin-right: 4px;
  }
  &.link::before {
    content: url("data:image/svg+xml,%3csvg width='22' height='14' viewBox='0 0 512 512' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M580.3 267.2c56.2-56.2 56.2-147.3 0-203.5C526.8 10.2 440.9 7.3 383.9 57.2l-6.1 5.4c-10 8.7-11 23.9-2.3 33.9s23.9 11 33.9 2.3l6.1-5.4c38-33.2 95.2-31.3 130.9 4.4c37.4 37.4 37.4 98.1 0 135.6L433.1 346.6c-37.4 37.4-98.2 37.4-135.6 0c-35.7-35.7-37.6-92.9-4.4-130.9l4.7-5.4c8.7-10 7.7-25.1-2.3-33.9s-25.1-7.7-33.9 2.3l-4.7 5.4c-49.8 57-46.9 142.9 6.6 196.4c56.2 56.2 147.3 56.2 203.5 0L580.3 267.2zM59.7 244.8C3.5 301 3.5 392.1 59.7 448.2c53.6 53.6 139.5 56.4 196.5 6.5l6.1-5.4c10-8.7 11-23.9 2.3-33.9s-23.9-11-33.9-2.3l-6.1 5.4c-38 33.2-95.2 31.3-130.9-4.4c-37.4-37.4-37.4-98.1 0-135.6L207 165.4c37.4-37.4 98.1-37.4 135.6 0c35.7 35.7 37.6 92.9 4.4 130.9l-5.4 6.1c-8.7 10-7.7 25.1 2.3 33.9s25.1 7.7 33.9-2.3l5.4-6.1c49.9-57 47-142.9-6.5-196.5c-56.2-56.2-147.3-56.2-203.5 0L59.7 244.8z' fill='%23707070'%3e%3c/path%3e%3c/svg%3e");
    position: relative;
    top: 2px;
    margin-left: 2px;
    margin-right: 2px;
  }

  & > span {
    display: inline-flex;
    padding: 2px 0 2px 2px;
  }

  .remove-variable {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    width: 24px;
    height: 24px;
    padding: 0px;
    margin-left: 4px;
    padding-left: 4px;

    background-color: $c-status_green_40;
    color: $c-surface_surface_100;

    border-top: 1px solid $c-status_green_60;
    border-bottom: 1px solid $c-status_green_60;
    border-right: 1px solid $c-status_green_60;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    &:hover {
      background-color: $c-status_green_60;
    }
  }
}
