.p-toast {
  width: 350px;
}

.p-toast.p-toast-top-center {
  left: 0;
  top: 0;
  transform: none;
  width: 100%;
  z-index: 1000;
}

.p-toast-message {
  min-height: 40px;
  color: $c-surface_surface_100;
  border-radius: 4px;
  border-left: solid 4px;
  display: flex;
  align-items: center;

  & + .p-toast-message {
    margin-top: 80px;
  }

  .p-toast-message-content {
    padding: 0 12px;
    align-items: center;
    height: 100%;
  }

  .p-toast-message-icon {
    font-size: 16px;
    line-height: 24px;
    margin-right: 8px;
  }

  .p-toast-message-text {
    font-size: 14px;
    text-align: left;
    line-height: 24px;
  }

  .p-toast-icon-close {
    width: 0.3rem;
    color: currentColor;
  }
}

.p-toast-message-error {
  background-color: $c-status_red_20;
  border-left-color: $c-status_red_60;

  .p-toast-message-icon {
    color: $c-surface_surface_100;

    &::before {
      @include fa-icon;
      font-family: fa-content($fa-style-family);
      content: fa-content($fa-var-hexagon-exclamation);
    }
  }
}

.p-toast-message-warn {
  background-color: $c-status_yellow_20;
  border-left-color: $c-status_yellow_60;

  .p-toast-message-icon {
    color: $c-surface_surface_100;

    &::before {
      @include fa-icon;
      font-family: fa-content($fa-style-family);
      content: fa-content($fa-var-exclamation-triangle);
    }
  }
}

.p-toast-message-success {
  background-color: $c-status_green_20;
  border-left-color: $c-status_green_60;

  .p-toast-message-icon {
    color: $c-surface_surface_100;

    &::before {
      @include fa-icon;
      font-family: fa-content($fa-style-family);
      content: fa-content($fa-var-check);
    }
  }
}

.p-toast-message-info {
  background-color: $c-status_blue_20;
  border-left-color: $c-status_blue_60;

  .p-toast-message-icon {
    color: $c-surface_surface_100;

    &::before {
      @include fa-icon;
      font-family: fa-content($fa-style-family);
      content: fa-content($fa-var-info-circle);
    }
  }
}
