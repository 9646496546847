.user-dropdown-menu {
  svg {
    color: $c-surface_surface_60;
  }
}

.p-dropdown-trigger {
  margin-left: 4px;
}

.p-dropdown-clear-icon {
  color: $c-primary_default;
  position: initial;
  margin: 4px 0px 0px 4px;
}

.p-dropdown {
  @include input-element;
}

.p-dropdown-panel {
  @include dropdown-panel;
}

.p-dropdown-items-wrapper {
  .p-dropdown-item {
    &.p-disabled {
      display: none;
    }
  }
}
.p-dropdown-item {
  padding: 6px;

  &:hover {
    background-color: $c-surface_surface_20;
  }
  // & + .p-dropdown-item {
  //   margin-top: 4px;
  // }
}

.p-dropdown.p-component.p-invalid {
  border-color: $c-status_red_80;
}

.p-dropdown.p-component.p-disabled {
  background-color: $c-surface_surface_white;
  opacity: 0.75;
}
