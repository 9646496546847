.header-panel {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: solid 1px $c-surface_surface_20;
  background-color: $c-surface_surface_white;
  display: flex;

  .p-toolbar {
    padding-top: 18px;
    padding-bottom: 18px;
    @include wrapper;
  }

  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    margin-right: 16px;
  }

  .p-toolbar-group-left {
    flex-grow: 5;
  }
  .p-toolbar-group-right {
    flex-grow: 2;
    & > span {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }
}
