.entry-card {
  margin: 0 auto;
  width: 100%;

  background: $c-surface_surface_white;
  border: 1px solid $c-surface_surface_30;
  border-radius: 4px;
  cursor: pointer;

  display: flex;

  &:hover {
    border: 1px solid $c-surface_surface_50;
    cursor: pointer;
  }

  &.selected {
    border: 1px solid $c-primary_default;
  }

  .status {
    width: 16px;

    .icon {
      color: $c-surface_surface_50;
      padding-left: 15px;
      padding-top: 20px;
    }
  }
  .detail {
    flex-grow: 1;
    margin: 16px 0;

    .name {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $c-surface_surface_100;
    }
    .description {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $c-surface_surface_60;
      margin-top: 4px;
    }
    .tags {
      display: flex;
      align-items: center;
      margin-top: 12px;
      gap: 8px;
    }
    .tag {
      display: flex;
      align-items: center;
      gap: 4px;
      background: $c-surface_surface_10;
      border: 1px solid $c-surface_surface_30;
      border-radius: 4px;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: $c-surface_surface_60;
      padding: 4px 8px;
      svg {
        color: $c-surface_surface_40;
      }
    }
  }
  .action {
    width: 36px;
    border-left: 1px solid $c-surface_surface_30;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      border-left: 1px solid $c-surface_surface_50;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      background-color: $c-surface_surface_20;
    }
    &.active {
      background-color: $c-surface_surface_20;
    }
    &.selected {
      border-left-color: $c-primary_default;
    }
  }
}
