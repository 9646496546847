.page-links {
  .links-content {
    height: 100%;
    overflow-y: auto;
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    .entry-card {
      max-width: 1000px;
    }
  }
}

.page-link-editor {
  &.link-preview {
    .content-editor-section {
      .body {
        padding: 0;
        overflow-x: auto;
      }
    }
  }
}
