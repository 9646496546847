.page-error {
  padding-top: 180px;
  text-align: center;
  color: $c-surface_surface_100;

  main {
    h1,
    svg {
      color: $c-primary_default;
    }
  }
}
