.p-avatar {
  background-color: $c-primary_hover;
  color: $c-surface_surface_white;
  font-size: 14px;
  height: 33px;
  width: 33px;

  &.p-avatar-circle {
    border-radius: 50%;
  }

  &.p-avatar-lg {
    width: 40px;
    height: 40px;

    font-size: 16px;
  }

  &.p-avatar-xl {
    width: 70px;
    height: 70px;

    font-size: 31px;
    border: solid 2px $c-surface_surface_white;
    border-width: 3px;
  }
}
