$c-surface_surface_white: #ffffff;
$c-surface_surface_transparent: #ffffff;
$c-surface_surface_overlay: rgba(37, 41, 46, 0.72);

$c-surface_surface_10: #f6f7f9;
$c-surface_surface_20: #e5e7ea;
$c-surface_surface_30: #9fa5ac;
$c-surface_surface_40: #9fa5ac;
$c-surface_surface_50: #80878f;
$c-surface_surface_60: #707070;
$c-surface_surface_70: #5a6065;
$c-surface_surface_80: #464c51;
$c-surface_surface_90: #393f44;
$c-surface_surface_100: #202020;

$c-primary_default: #ba1b02;
$c-primary_hover: #a71802;
$c-primary_select: #821301;

$c-status_red_10: #fde8e7;
$c-status_red_20: #f8c3c0;
$c-status_red_40: #f28882;
$c-status_red_60: #eb4c43;
$c-status_red_80: #9d332d;
$c-status_red_100: #4e1916;

$c-status_yellow_10: #fef4e7;
$c-status_yellow_20: #fbe4be;
$c-status_yellow_40: #f8c97e;
$c-status_yellow_60: #f4ae3d;
$c-status_yellow_80: #a37429;
$c-status_yellow_100: #513a14;

$c-status_green_20: #c9e4d5;
$c-status_green_40: #93caac;
$c-status_green_60: #5daf82;
$c-status_green_80: #3e7557;
$c-status_green_100: #1f3a2b;

$c-status_blue_20: #c6e6f7;
$c-status_blue_40: #8ccdef;
$c-status_blue_60: #53b4e7;
$c-status_blue_80: #37789a;
$c-status_blue_100: #1c3c4d;

$c-hyperlink_default: #145fd7;

$font-family: 'Roboto', 'Arial', sans-serif;
$box-shadow: $c-surface_surface_overlay;
