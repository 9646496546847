.p-menu,
.p-contextmenu {
  background-color: $c-surface_surface_white;
  box-shadow: 0px 1px 3px $box-shadow;
  border-radius: 4px;
  min-width: 220px;
  padding: 2px;

  .p-menuitem {
    display: flex;
    align-items: center;

    &:hover {
      border-radius: 4px;
      background-color: $c-surface_surface_20;
    }

    &.danger .p-menuitem-link {
      color: $c-status_red_60;
      svg {
        color: $c-status_red_60 !important;
      }
    }
  }

  .p-menuitem-link {
    color: $c-surface_surface_100;
    padding-bottom: 1px;
    width: 100%;
    svg {
      color: $c-surface_surface_60;
    }
  }
}

.p-menuitem-link {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  svg {
    margin-right: 10px;
    color: $c-surface_surface_white;
  }
}

.actions {
  .p-tabmenu .p-tabmenuitem {
    height: 40px;
    padding: 0;

    a.p-menuitem-link {
      padding: 6px 16px 15px 16px;
    }
  }
}

.p-menuitem-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
}

.p-disabled .p-menuitem-text {
  opacity: 0.4;
}

.p-contextmenu,
.p-menu-list {
  .p-menuitem {
    height: 40px;
    padding: 0;

    a.p-menuitem-link {
      height: 40px;
      padding: 8px 12px;
    }
  }
}

.p-menuitem {
  height: 40px;
  padding: 8px 12px;
}

.p-menu-separator {
  border-bottom: solid 1px $c-surface_surface_20;
  margin: 4px 0;
}

.p-menuitem-icon {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  margin-right: 8px;
  width: 20px;
  color: $c-surface_surface_60;
}

.p-menubar {
  .p-submenu-icon::after {
    @include fa-icon;
    font-family: fa-content($fa-style-family);
    content: fa-content($fa-var-angle-down);
    padding-left: 4px;
  }

  .p-submenu-list {
    background-color: $c-surface_surface_white;
    box-shadow: 0px 2px 4px $box-shadow;
    border-radius: 4px;
    padding: 2px;
    margin-top: 14px;
    min-width: 220px;
    left: 0px;

    .p-menuitem-link {
      color: $c-surface_surface_100;
    }
    .p-menuitem {
      margin-right: 0px !important;
    }
    .p-menuitem-active {
      &:hover {
        background-color: $c-surface_surface_20;
        border-radius: 4px;
      }
    }
  }

  .p-menuitem {
    &:hover {
      background-color: rgba($c-surface_surface_white, 0.2);
      color: $c-surface_surface_white;
    }
  }

  .p-menuitem-active {
    background-color: rgba($c-surface_surface_20, 0.2);
    border-radius: 4px;

    .p-submenu-icon::after {
      @include fa-icon;
      font-family: fa-content($fa-style-family);
      content: fa-content($fa-var-angle-up);
      padding-left: 4px;
    }
  }

  .p-menuitem-link {
    color: $c-surface_surface_white;
  }
}

.p-tabmenu {
  border-bottom: 1px solid $c-surface_surface_30;
  padding: 0 16px;

  .p-tabmenuitem {
    padding: 12px 16px;
    border-bottom: 1px solid transparent;

    &.p-highlight {
      .p-menuitem-link {
        color: $c-primary_default;
      }
      // border-bottom-left-radius: 4px;
      // border-bottom-right-radius: 4px;
      border-bottom: solid 4px $c-primary_default;
      &.p-error {
        border-bottom: solid 4px $c-status_red_100;
      }
    }

    &:not(.p-highlight):hover {
      // border-bottom-left-radius: 4px;
      // border-bottom-right-radius: 4px;
      border-bottom: solid 4px rgba($c-primary_default, 0.4);
    }
    &.p-error:hover {
      // border-bottom-left-radius: 4px;
      // border-bottom-right-radius: 4px;
      border-bottom: solid 4px rgba($c-status_red_100, 0.4);
    }
  }

  .p-tabmenuitem + .p-tabmenuitem {
    margin-left: 0px;
  }

  .p-menuitem-link {
    color: $c-surface_surface_100;
  }
}
