.content-with-header {
  color: $c-surface_surface_100;
  background: $c-surface_surface_white;
  border: 1px solid $c-surface_surface_30;
  border-radius: 4px;
  margin-bottom: 16px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    .content {
      margin-right: auto;
    }
    .show-button {
      .p-button {
        color: $c-surface_surface_100;
        background: $c-surface_surface_white;
        border: 1px solid $c-surface_surface_30;
        border-radius: 4px;
        padding: 4px 12px 4px 2px;
        &:hover {
          border-color: $c-surface-surface-60;
          background-color: $c-surface_surface_20;
        }
        &:active {
          background-color: $c-surface_surface_30;
        }
      }
    }
    .svg-inline--fa {
      margin: 4px 8px;
    }
  }
  .body {
    border-top: 1px solid $c-surface_surface_30;
    padding: 16px;
  }
}
