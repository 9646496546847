.overlay {
}
.overlay-full:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $c-surface_surface_overlay;
}
